import { TestKit } from "@/models/test-kit";

export interface User {
  id: string;
  email: string;
  businessName: string;
  createdAt: Date;
  updatedAt: Date;
  firstName: string;
  lastName: string;
  status: UserStatus;
  defaultSignature: string;
  testKit: TestKit;
  testerId: string;
}

export enum UserStatus {
  Created = "Created",
  Completed = "Completed",
}

export enum UserKeyCharLimit {
  testerId = 13,
}
