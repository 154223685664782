import { defineStore } from "pinia";
import { User, UserStatus } from "@/models/user";
import { useFirebaseAuth } from "vuefire";
import UserClient from "@/clients/user-client";

export type RootUserState = {
  user: User;
  isAuthenticated: boolean;
  isNew: boolean;
};

export const defaultUser = (): User => {
  return {
    id: "",
    createdAt: new Date(),
    defaultSignature: "",
    firstName: "",
    lastName: "",
    status: UserStatus.Created,
    testKit: {
      manufacturer: "",
      serialNumber: "",
      dateCertified: new Date(),
    },
    updatedAt: new Date(),
    email: "",
    businessName: "",
    testerId: "",
  };
};

export const useUserStore = defineStore("user", {
  state: (): RootUserState => {
    return {
      isAuthenticated: false,
      isNew: true,
      user: defaultUser(),
    };
  },
  getters: {
    isUserAuthenticated(): boolean {
      return this.isAuthenticated;
    },
  },
  actions: {
    updateUser(user: User) {
      this.user = user;
    },
    init(): void {
      useFirebaseAuth()!.onAuthStateChanged(async (authUser) => {
        try {
          if (authUser) {
            this.user.id = authUser.uid;
            this.user = await UserClient.getInstance().getUser();
            this.isAuthenticated = true;
          } else {
            this.isAuthenticated = false;
            this.user = defaultUser();
          }
        } catch (e) {
          console.error(e);
          this.isAuthenticated = false;
          this.user = defaultUser();
        }
        this.isNew = false;
      });
    },
  },
});
