import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import { useFirebaseAuth } from "vuefire";
import { signOut } from "firebase/auth";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/store/user-store";
import { emitter } from "@/event-bus";

@Options({})
export default class AppBar extends Vue {
  @Prop({ required: false, default: true }) showLinks!: boolean;

  private userStore = useUserStore();

  public drawer = false;

  public activeTab = 0;

  private router = useRouter();

  public tabs = [
    { id: 0, name: "home", display: "Home" },
    { id: 1, name: "groups", display: "Groups" },
    { id: 2, name: "backflows", display: "Backflows" },
  ];

  public mounted() {
    this.init();

    emitter.on("open-drawer", () => {
      this.drawer = true;
    });
  }

  private async init() {
    await this.router.isReady();
    this.setTab();
  }

  @Watch("$route.name")
  private tabUpdated() {
    this.setTab();
  }

  private setTab() {
    const pathTab = this.tabs.find((tab) => tab.name === this.$route.name);
    if (pathTab) {
      this.activeTab = pathTab.id;
    }
  }

  public onTabClick(tab: string) {
    this.$router.push({ name: tab });
  }

  public onNavClick(value: string) {
    this.activeTab = 0;
    this.$router.push({ name: value });
  }

  public async onLogoutClick() {
    try {
      await signOut(useFirebaseAuth()!);
      await this.$router.push({ name: "login" });
    } catch (e) {
      console.error(e);
      await this.$router.push({ name: "login" });
    }
  }

  public get userName() {
    if (!this.userStore.isUserAuthenticated) return `Account`;
    return this.userStore.user.businessName;
  }
}
