import { createApp } from "vue";
import { createPinia } from "pinia";
import { VueFire, VueFireAuth } from "vuefire";
import App from "./app.vue";
import { loadFonts } from "./plugins/webfontloader";
import vuetify from "./plugins/vuetify";
import router from "./router/router";
import firebaseApp from "./clients/firebase";
import { VueSignaturePad } from "vue-signature-pad";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

loadFonts();
const pinia = createPinia();

const app = createApp(App);
app.component("VueSignaturePad", VueSignaturePad);
app.component("VuePicDatePicker", VueDatePicker);

app
  .use(vuetify)
  .use(router)
  .use(VueFire, {
    firebaseApp,
    modules: [VueFireAuth()],
  })
  .use(pinia);

app.mount("#app");
