import firestore from "@/clients/firestore";
import {
  DocumentData,
  QueryDocumentSnapshot,
  setDoc,
  doc,
  WithFieldValue,
  Timestamp,
  getDoc,
} from "firebase/firestore";
import { useUserStore } from "@/store/user-store";
import { User } from "@/models/user";

export default class UserClient {
  private static instance: UserClient;

  private static USERS_COLLECTION = "users";

  private userStore = useUserStore();

  public async getUser(): Promise<User> {
    try {
      const docRef = doc(
        firestore,
        UserClient.USERS_COLLECTION,
        this.userStore.user.id
      );
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) throw new Error("not found");
      return this.getUserFromDocData(docSnap);
    } catch (e) {
      console.error("unable to get user", e);
      throw e;
    }
  }

  public async createUser(user: User): Promise<void> {
    try {
      await setDoc(
        doc(firestore, UserClient.USERS_COLLECTION, this.userStore.user.id),
        this.getDocDataFromUser(user)
      );
    } catch (e) {
      console.error("unable to create user", e);
      throw e;
    }
  }

  public async updateUser(): Promise<void> {
    try {
      await setDoc(
        doc(firestore, UserClient.USERS_COLLECTION, this.userStore.user.id),
        this.getDocDataFromUser(this.userStore.user)
      );
    } catch (e) {
      console.error("unable to update user", e);
      throw e;
    }
  }

  private getUserFromDocData(
    doc: QueryDocumentSnapshot<DocumentData, DocumentData>
  ): User {
    const docData = doc.data();
    return {
      businessName: docData.businessName,
      defaultSignature: docData.defaultSignature,
      email: docData.email,
      firstName: docData.firstName,
      lastName: docData.lastName,
      status: docData.status,
      testKit: {
        manufacturer: docData.testKit.manufacturer,
        serialNumber: docData.testKit.serialNumber,
        dateCertified: (docData.testKit.dateCertified as Timestamp).toDate(),
      },
      id: doc.id,
      createdAt: (docData.createdAt as Timestamp).toDate(),
      updatedAt: (docData.updatedAt as Timestamp).toDate(),
      testerId: docData.testerId,
    };
  }

  private getDocDataFromUser(user: User): WithFieldValue<any> {
    return {
      email: user.email,
      userUid: user.id,
      businessName: user.businessName,
      createdAt: Timestamp.fromDate(user.createdAt),
      updatedAt: Timestamp.fromDate(user.updatedAt),
      firstName: user.firstName,
      lastName: user.lastName,
      status: user.status,
      defaultSignature: user.defaultSignature,
      testKit: {
        manufacturer: user.testKit.manufacturer,
        serialNumber: user.testKit.serialNumber,
        dateCertified: Timestamp.fromDate(user.testKit.dateCertified),
      },
      testerId: user.testerId,
    };
  }

  public static getInstance(): UserClient {
    if (!UserClient.instance) {
      UserClient.instance = new UserClient();
    }
    return UserClient.instance;
  }
}
