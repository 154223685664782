import { createRouter, createWebHistory } from "vue-router";
import authGuard from "@/router/auth-guard";
import { nextTick } from "vue";

const DEFAULT_TITLE = "CrossConnected";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      meta: { title: "CrossConnected Home" },
      component: () => import("../views/home-view/home-view.vue"),
    },
    {
      path: "/groups",
      name: "groups",
      meta: { title: "CrossConnected Groups" },
      component: () => import("../views/groups-view/groups-view.vue"),
    },
    {
      path: "/backflows",
      name: "backflows",
      meta: { title: "CrossConnected Backflows" },
      component: () => import("../views/backflows-view/backflows-view.vue"),
    },
    {
      path: "/login",
      name: "login",
      meta: { title: "CrossConnected Login" },
      component: () => import("../views/login-view/login-view.vue"),
    },
    {
      path: "/backflows/:backflowUid/tests",
      name: "tests",
      meta: { title: "CrossConnected Tests" },
      component: () => import("../views/tests-view/tests-view.vue"),
    },
    {
      path: "/settings",
      name: "settings",
      meta: { title: "CrossConnected Settings" },
      component: () => import("../views/settings-view/settings-view.vue"),
    },
  ],
});

router.beforeEach(authGuard);

router.afterEach((to, from) => {
  nextTick(() => {
    document.title = (to.meta.title as string) || DEFAULT_TITLE;
  });
});

export default router;
