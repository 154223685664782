import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import {getCurrentUser} from "vuefire";

const authGuard = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
  const currentUser = await getCurrentUser()

  if (to.name === 'login' && currentUser) {
    return next({name: 'home'});
  }
  if (currentUser || to.name === 'login') {
    next();
  } else {
    next({ name: 'login' });
  }
}
export default authGuard