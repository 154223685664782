import { Vue, Options } from "vue-property-decorator";
import AppBar from "@/components/app-bar/app-bar.vue";

@Options({
  components: {
    AppBar,
  },
})
export default class AppLayoutUnauthenticated extends Vue {
  mounted() {}
}
