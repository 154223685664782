import { Vue, Options } from "vue-property-decorator";
import { useUserStore } from "@/store/user-store";
import AppLayoutAuthenticated from "@/layouts/app-layout-authenticated/app-layout-authenticated.vue";
import AppLayoutUnauthenticated from "@/layouts/app-layout-unauthenticated/app-layout-unauthenticated.vue";

@Options({
  components: {
    AppLayoutAuthenticated,
    AppLayoutUnauthenticated,
  },
})
export default class AppLayout extends Vue {
  private userStore = useUserStore();

  public get isUserAuthenticated(): boolean {
    return this.userStore.isUserAuthenticated;
  }
}
