import { FirebaseOptions, initializeApp } from "firebase/app";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCOAz9KibUE-bkxPnOqKiECEW3PB7Egcvo",
  authDomain: "cross-connected.firebaseapp.com",
  databaseURL: "https://cross-connected-default-rtdb.firebaseio.com",
  projectId: "cross-connected",
  storageBucket: "cross-connected.appspot.com",
  messagingSenderId: "1017202542189",
  appId: "1:1017202542189:web:ae8812997238fb70662164",
  measurementId: "G-YDMMK8WK5H",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
