// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VDataTable, VDataTableServer } from "vuetify/labs/VDataTable";
import { VDatePicker } from "vuetify/labs/VDatePicker";

const vuetify = createVuetify({
  components: {
    VDataTable,
    VDataTableServer,
    VDatePicker,
    ...components,
  },
  directives,
});

export default vuetify;
