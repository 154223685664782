import { Options, Vue } from "vue-property-decorator";
import AppLayout from "@/layouts/app-layout.vue";
import { useUserStore } from "@/store/user-store";
import packageJson from "../package.json";

@Options({
  components: {
    AppLayout,
  },
})
export default class App extends Vue {
  private userStore = useUserStore();

  mounted(): void {
    this.userStore.init();
  }

  get version() {
    return packageJson.version;
  }
}
